body {
  font-family: "Poppins", sans-serif;
  color: #0c0c0c;
  background-color: #ffffff;
}

.layout_padding {
  padding: 75px 0;
}

.layout_padding2 {
  padding: 45px 0;
}

.layout_padding2-top {
  padding-top: 45px;
}

.layout_padding2-bottom {
  padding-bottom: 45px;
}

.layout_padding-top {
  padding-top: 75px;
}

.layout_padding-bottom {
  padding-bottom: 75px;
}

.heading_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.heading_container h2 {
  font-weight: bold;
  color: #050000;
  padding: 3px 10px;
  border-bottom: 1px solid #000000;
}
