/* about section */
.about_section .img-box img {
  width: 100%;
}

.about_section .detail-box a {
  display: inline-block;
  padding: 8px 35px;
  background-color: #e93f1a;
  color: #ffffff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid transparent;
  border-radius: 5px;
  margin-top: 25px;
}

.about_section .detail-box a:hover {
  background-color: transparent;
  border-color: #e93f1a;
  color: #e93f1a;
}

/* end about section */

/* service section */

.body_bg {
  background-image: url(/public/assets/body_bg.jpg);
  background-size: cover;
}

.service_section .heading_container {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 25px;
}

.service_section .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 15px 0;
  width: 85%;
}

.service_section .box h4 {
  margin-top: 5px;
}

.service_section .box a {
  padding: 6px 25px;
  border: 1px solid #e93f1a;
  color: #e93f1a;
  text-transform: uppercase;
}

/* end service section */

/* contact section */
.info_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.info_section .footer_contact {
  width: 350px;
}

.info_section .footer_contact .heading_container h2 {
  width: 100%;
  text-align: center;
}

.info_section .footer_contact .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 35px 20px 0 20px;
}

.info_section .footer_contact .box .img-box .img-2 {
  display: none;
}

.info_section .footer_contact .box .img-box:hover .img-1 {
  display: none;
}

.info_section .footer_contact .box .img-box:hover .img-2 {
  display: inline;
}
/* end contact section */
