#blog-list {
  padding: 80px;
}

.blog-post-summary {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.blog-post-summary h2 {
  margin: 0;
}

.blog-post-summary p {
  margin: 5px 0;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.section {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.section .heading {
  font-size: 40px;
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .section .heading {
    font-size: 30px;
  }
}
