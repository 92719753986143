/*header section*/
.hero_area {
  height: auto;
  background-image: url(/public/assets/hero-bg.jpg);
  background-size: cover;
}

.show_slider {
  height: 100vh !important;
}

.header_section .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

.header_section .nav_container {
  margin: 0 auto;
}

.custom_nav-container.navbar-expand-lg .navbar-nav .nav-item .nav-link {
  padding: 10px 30px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom_nav-container .nav_search-btn {
  background-image: url(/public/assets/search-icon.png);
  background-size: 22px;
  background-repeat: no-repeat;
  background-position-y: 7px;
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
}

.navbar-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-brand img {
  width: 20px;
  margin-right: 5px;
}

.navbar-brand span {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
}

.custom_nav-container {
  z-index: 99999;
  padding: 15px 0;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler .navbar-toggler-icon {
  background-image: url(/public/assets/menu.png);
  background-size: 55px;
}

/*end header section*/
