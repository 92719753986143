#post-page {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #888;
  background-color: #888;
}

code {
  color: unset;
}
